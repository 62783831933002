@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  /* Yahav */
  /* --brand-primary-color: #2d3377;
  --brand-primary-darker-color: #2d3377;
  --brand-primary-hover-color: #2d3377; */
   /* --brand-primary-color: #3A880F; */ /* Discount */
  --brand-primary-color: #004ba5; /* Leumi */
  --brand-primary-darker-color: #00326f;
  --brand-primary-hover-color: #00326f;
  --brand-secondary-color: #d9d9d9;
  --brand-secondary-font-color: #616161;
  --brand-third-color: #f2f2f2;
  --brand-red-color: #a61313;
  --brand-awaiting-color: #f1d6d6;
  --input-stale-color: #f6f4f4;
  --input-focus-color: #faf9f9;
  --input-selection-color: #d3d3d3;
  --text-secondary-color: #666666;
}

[role="button"] {
  cursor: unset;
}

@font-face {
  font-family: Alef;
  src: url(../fonts/Alef-Regular.ttf);
}

@font-face {
  font-family: Alef-Bold;
  src: url(../fonts/Alef-Bold.ttf);
}

body {
  font-family: "Rubik", sans-serif;
}

label {
  color: var(--text-secondary-color);
}

input.form-control {
  border: 0;
  background-color: var(--input-stale-color);
  padding: 1rem;
}

input.form-control::selection {
  background-color: var(--input-selection-color);
}

input.form-control:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  background-color: var(--input-focus-color);
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:hover,
input.form-control:-webkit-autofill:focus,
input.form-control:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px var(--input-stale-color) inset !important;
  box-shadow: 0 0 0 30px var(--input-stale-color) inset !important;
}

.btn:focus {
  box-shadow: unset;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.25;
}

.bg-brand-primary {
  /* Discount */
  /* background-image: linear-gradient(to right,#3A880F,#008042 67%,#00745F); */
  /* Poalim */
  /* background-image: linear-gradient(to right,#c01039,#dc1223 67%,#de1e1e 84%,#e02a19); */

  /* Yayhav */
  /* background-image: linear-gradient(to right, #3ba7be , #377da8 , #2e3280); */

  /* background-color: var(--brand-primary-darker-color); */
  background-color: var(--brand-primary-color);
}

.bg-brand-primary:hover {
  /* background-image: unset; */
  background-color: var(--brand-primary-darker-color);
}

.bg-brand-secondary {
  background-color: var(--brand-secondary-color);
}

.bg-brand-third {
  background-color: var(--brand-third-color);
}

.bg-awaiting {
  background-color: var(--brand-awaiting-color);
}

.text-brand-primary {
  color: var(--brand-primary-color);
}

.text-brand-secondary {
  color: var(--brand-secondary-color);
}

.text-brand-third {
  color: var(--brand-third-color);
}

.text-brand-red {
  color: var(--brand-red-color);
}

.fg-awaiting {
  color: var(--brand-awaiting-color);
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-brand-primary {
  color: var(--brand-primary-color);
}

.text-brand-primary:hover {
  color: var(--brand-primary-darker-color);
}

.text-small {
  font-size: 0.8rem;
  font-weight: 400;
}

.text-jumbo {
  font-size: 5rem;
  font-weight: 500;
}

.text-secondary {
  color: var(--text-secondary-color);
}

.opacity-1 {
  opacity: 1 !important;
}

.border-brand-primary {
  border-color: var(--brand-primary-color);
}

.border-brand-primary:hover {
  border-color: var(--brand-primary-darker-color);
}

.border-brand-secondary {
  border-color: var(--brand-secondary-color);
}

.line-height-1 {
  line-height: 1;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.modal-header {
  border: 0;
}

.modal-subtitle {
  color: #4a4a4a;
}

.modal-body {
  margin: 0;
  padding: 0;
}

.modal-footer {
  border: 0;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.circle {
  border-radius: 50% !important;
}

.border-dashed {
  border-style: dashed !important;
}

.cursor-pointer {
  cursor: pointer !important;
  user-select: none;
}

.cursor-arrow {
  cursor: unset !important;
}

.rotate-180 {
  transform: rotate(180deg) !important;
}

.w-1rem {
  width: 1rem;
}

.w-2rem {
  width: 2rem;
}

.w-3rem {
  width: 3rem;
}

.w-7rem {
  width: 7rem;
}

.w-12rem {
  width: 12rem;
}

.w-15rem {
  width: 15rem;
}

.h-1rem {
  height: 1rem;
}

.h-2rem {
  height: 2rem;
}

.h-3rem {
  height: 3rem;
}

.h-100vh {
  height: 100vh;
}

.child-account-add-btn {
  width: 100%;
}

.sign-in-page {
  width: 100%;
}

.background-image::before {
  content: "";
  position: absolute;
  top: 225px;
  left: 0;
  width: 100%;
  height: calc(100% - 225px);
  z-index: -1;
  background-image: url("../images/background.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@media (min-width: 768px) {
  .background-image::before {
    height: calc(100% - 120px);
    top: 120px;
  }
}

@media (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-start {
    text-align: start !important;
  }

  .text-lg-end {
    text-align: end !important;
  }

  .child-account-card {
    width: 100%;
  }

  .child-account-add-btn {
    max-width: 320px;
  }

  .child-account-add-otp {
    direction: ltr;
  }

  .sign-in-page {
    max-width: 800px;
    margin: auto;
  }
}

select.form-control {
  background: var(--input-stale-color);
  border: none;
}

.btn-lg {
  font-size: 1rem !important;
}
