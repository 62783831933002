@import url(https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap);
:root {
  /* Yahav */
  /* --brand-primary-color: #2d3377;
  --brand-primary-darker-color: #2d3377;
  --brand-primary-hover-color: #2d3377; */
   /* --brand-primary-color: #3A880F; */ /* Discount */
  --brand-primary-color: #004ba5; /* Leumi */
  --brand-primary-darker-color: #00326f;
  --brand-primary-hover-color: #00326f;
  --brand-secondary-color: #d9d9d9;
  --brand-secondary-font-color: #616161;
  --brand-third-color: #f2f2f2;
  --brand-red-color: #a61313;
  --brand-awaiting-color: #f1d6d6;
  --input-stale-color: #f6f4f4;
  --input-focus-color: #faf9f9;
  --input-selection-color: #d3d3d3;
  --text-secondary-color: #666666;
}

[role="button"] {
  cursor: unset;
}

@font-face {
  font-family: Alef;
  src: url(/static/media/Alef-Regular.556531b6.ttf);
}

@font-face {
  font-family: Alef-Bold;
  src: url(/static/media/Alef-Bold.59a2c468.ttf);
}

body {
  font-family: "Rubik", sans-serif;
}

label {
  color: #666666;
  color: var(--text-secondary-color);
}

input.form-control {
  border: 0;
  background-color: #f6f4f4;
  background-color: var(--input-stale-color);
  padding: 1rem;
}

input.form-control::selection {
  background-color: #d3d3d3;
  background-color: var(--input-selection-color);
}

input.form-control:focus {
  outline-style: none;
  box-shadow: none;
  border-color: transparent;
  background-color: #faf9f9;
  background-color: var(--input-focus-color);
}

input.form-control:-webkit-autofill,
input.form-control:-webkit-autofill:hover,
input.form-control:-webkit-autofill:focus,
input.form-control:-webkit-autofill:active {
  box-shadow: 0 0 0 30px #f6f4f4 inset !important;
  box-shadow: 0 0 0 30px var(--input-stale-color) inset !important;
}

.btn:focus {
  box-shadow: unset;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  opacity: 0.25;
}

.bg-brand-primary {
  /* Discount */
  /* background-image: linear-gradient(to right,#3A880F,#008042 67%,#00745F); */
  /* Poalim */
  /* background-image: linear-gradient(to right,#c01039,#dc1223 67%,#de1e1e 84%,#e02a19); */

  /* Yayhav */
  /* background-image: linear-gradient(to right, #3ba7be , #377da8 , #2e3280); */

  /* background-color: var(--brand-primary-darker-color); */
  background-color: #004ba5;
  background-color: var(--brand-primary-color);
}

.bg-brand-primary:hover {
  /* background-image: unset; */
  background-color: #00326f;
  background-color: var(--brand-primary-darker-color);
}

.bg-brand-secondary {
  background-color: #d9d9d9;
  background-color: var(--brand-secondary-color);
}

.bg-brand-third {
  background-color: #f2f2f2;
  background-color: var(--brand-third-color);
}

.bg-awaiting {
  background-color: #f1d6d6;
  background-color: var(--brand-awaiting-color);
}

.text-brand-primary {
  color: #004ba5;
  color: var(--brand-primary-color);
}

.text-brand-secondary {
  color: #d9d9d9;
  color: var(--brand-secondary-color);
}

.text-brand-third {
  color: #f2f2f2;
  color: var(--brand-third-color);
}

.text-brand-red {
  color: #a61313;
  color: var(--brand-red-color);
}

.fg-awaiting {
  color: #f1d6d6;
  color: var(--brand-awaiting-color);
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-brand-primary {
  color: #004ba5;
  color: var(--brand-primary-color);
}

.text-brand-primary:hover {
  color: #00326f;
  color: var(--brand-primary-darker-color);
}

.text-small {
  font-size: 0.8rem;
  font-weight: 400;
}

.text-jumbo {
  font-size: 5rem;
  font-weight: 500;
}

.text-secondary {
  color: #666666;
  color: var(--text-secondary-color);
}

.opacity-1 {
  opacity: 1 !important;
}

.border-brand-primary {
  border-color: #004ba5;
  border-color: var(--brand-primary-color);
}

.border-brand-primary:hover {
  border-color: #00326f;
  border-color: var(--brand-primary-darker-color);
}

.border-brand-secondary {
  border-color: #d9d9d9;
  border-color: var(--brand-secondary-color);
}

.line-height-1 {
  line-height: 1;
}

.show {
  display: block;
}

.hide {
  display: none;
}

.modal-header {
  border: 0;
}

.modal-subtitle {
  color: #4a4a4a;
}

.modal-body {
  margin: 0;
  padding: 0;
}

.modal-footer {
  border: 0;
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.font-weight-bold {
  font-weight: bold !important;
}

.circle {
  border-radius: 50% !important;
}

.border-dashed {
  border-style: dashed !important;
}

.cursor-pointer {
  cursor: pointer !important;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.cursor-arrow {
  cursor: unset !important;
}

.rotate-180 {
  -webkit-transform: rotate(180deg) !important;
          transform: rotate(180deg) !important;
}

.w-1rem {
  width: 1rem;
}

.w-2rem {
  width: 2rem;
}

.w-3rem {
  width: 3rem;
}

.w-7rem {
  width: 7rem;
}

.w-12rem {
  width: 12rem;
}

.w-15rem {
  width: 15rem;
}

.h-1rem {
  height: 1rem;
}

.h-2rem {
  height: 2rem;
}

.h-3rem {
  height: 3rem;
}

.h-100vh {
  height: 100vh;
}

.child-account-add-btn {
  width: 100%;
}

.sign-in-page {
  width: 100%;
}

.background-image::before {
  content: "";
  position: absolute;
  top: 225px;
  left: 0;
  width: 100%;
  height: calc(100% - 225px);
  z-index: -1;
  background-image: url(/static/media/background.18904304.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}

@media (min-width: 768px) {
  .background-image::before {
    height: calc(100% - 120px);
    top: 120px;
  }
}

@media (min-width: 992px) {
  .text-lg-right {
    text-align: right !important;
  }

  .text-lg-left {
    text-align: left !important;
  }

  .text-lg-start {
    text-align: start !important;
  }

  .text-lg-end {
    text-align: end !important;
  }

  .child-account-card {
    width: 100%;
  }

  .child-account-add-btn {
    max-width: 320px;
  }

  .child-account-add-otp {
    direction: ltr;
  }

  .sign-in-page {
    max-width: 800px;
    margin: auto;
  }
}

select.form-control {
  background: #f6f4f4;
  background: var(--input-stale-color);
  border: none;
}

.btn-lg {
  font-size: 1rem !important;
}

.m-0 {
  margin: 0rem !important;
}
.m-025 {
  margin: 0.125rem !important;
}
.m-05 {
  margin: 0.25rem !important;
}
.m-1 {
  margin: 0.5rem !important;
}
.m-2 {
  margin: 1rem !important;
}
.m-3 {
  margin: 1.5rem !important;
}
.m-4 {
  margin: 2rem !important;
}
.m-5 {
  margin: 2.5rem !important;
}
.m-6 {
  margin: 3rem !important;
}
.m-7 {
  margin: 3.5rem !important;
}
.m-8 {
  margin: 4rem !important;
}
.m-9 {
  margin: 4.5rem !important;
}
.m-10 {
  margin: 5rem !important;
}
.mt-0 {
  margin-top: 0rem !important;
}
.mt-025 {
  margin-top: 0.125rem !important;
}
.mt-05 {
  margin-top: 0.25rem !important;
}
.mt-1 {
  margin-top: 0.5rem !important;
}
.mt-2 {
  margin-top: 1rem !important;
}
.mt-3 {
  margin-top: 1.5rem !important;
}
.mt-4 {
  margin-top: 2rem !important;
}
.mt-5 {
  margin-top: 2.5rem !important;
}
.mt-6 {
  margin-top: 3rem !important;
}
.mt-7 {
  margin-top: 3.5rem !important;
}
.mt-8 {
  margin-top: 4rem !important;
}
.mt-9 {
  margin-top: 4.5rem !important;
}
.mt-10 {
  margin-top: 5rem !important;
}
.mb-0 {
  margin-bottom: 0rem !important;
}
.mb-025 {
  margin-bottom: 0.125rem !important;
}
.mb-05 {
  margin-bottom: 0.25rem !important;
}
.mb-1 {
  margin-bottom: 0.5rem !important;
}
.mb-2 {
  margin-bottom: 1rem !important;
}
.mb-3 {
  margin-bottom: 1.5rem !important;
}
.mb-4 {
  margin-bottom: 2rem !important;
}
.mb-5 {
  margin-bottom: 2.5rem !important;
}
.mb-6 {
  margin-bottom: 3rem !important;
}
.mb-7 {
  margin-bottom: 3.5rem !important;
}
.mb-8 {
  margin-bottom: 4rem !important;
}
.mb-9 {
  margin-bottom: 4.5rem !important;
}
.mb-10 {
  margin-bottom: 5rem !important;
}
.ml-0 {
  margin-left: 0rem !important;
}
.ml-025 {
  margin-left: 0.125rem !important;
}
.ml-05 {
  margin-left: 0.25rem !important;
}
.ml-1 {
  margin-left: 0.5rem !important;
}
.ml-2 {
  margin-left: 1rem !important;
}
.ml-3 {
  margin-left: 1.5rem !important;
}
.ml-4 {
  margin-left: 2rem !important;
}
.ml-5 {
  margin-left: 2.5rem !important;
}
.ml-6 {
  margin-left: 3rem !important;
}
.ml-7 {
  margin-left: 3.5rem !important;
}
.ml-8 {
  margin-left: 4rem !important;
}
.ml-9 {
  margin-left: 4.5rem !important;
}
.ml-10 {
  margin-left: 5rem !important;
}
.mr-0 {
  margin-right: 0rem !important;
}
.mr-025 {
  margin-right: 0.125rem !important;
}
.mr-05 {
  margin-right: 0.25rem !important;
}
.mr-1 {
  margin-right: 0.5rem !important;
}
.mr-2 {
  margin-right: 1rem !important;
}
.mr-3 {
  margin-right: 1.5rem !important;
}
.mr-4 {
  margin-right: 2rem !important;
}
.mr-5 {
  margin-right: 2.5rem !important;
}
.mr-6 {
  margin-right: 3rem !important;
}
.mr-7 {
  margin-right: 3.5rem !important;
}
.mr-8 {
  margin-right: 4rem !important;
}
.mr-9 {
  margin-right: 4.5rem !important;
}
.mr-10 {
  margin-right: 5rem !important;
}
.mx-0 {
  margin-left: 0rem !important;
  margin-right: 0rem !important;
}
.mx-025 {
  margin-left: 0.125rem !important;
  margin-right: 0.125rem !important;
}
.mx-05 {
  margin-left: 0.25rem !important;
  margin-right: 0.25rem !important;
}
.mx-1 {
  margin-left: 0.5rem !important;
  margin-right: 0.5rem !important;
}
.mx-2 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}
.mx-3 {
  margin-left: 1.5rem !important;
  margin-right: 1.5rem !important;
}
.mx-4 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}
.mx-5 {
  margin-left: 2.5rem !important;
  margin-right: 2.5rem !important;
}
.mx-6 {
  margin-left: 3rem !important;
  margin-right: 3rem !important;
}
.mx-7 {
  margin-left: 3.5rem !important;
  margin-right: 3.5rem !important;
}
.mx-8 {
  margin-left: 4rem !important;
  margin-right: 4rem !important;
}
.mx-9 {
  margin-left: 4.5rem !important;
  margin-right: 4.5rem !important;
}
.mx-10 {
  margin-left: 5rem !important;
  margin-right: 5rem !important;
}
.my-0 {
  margin-top: 0rem !important;
  margin-bottom: 0rem !important;
}
.my-025 {
  margin-top: 0.125rem !important;
  margin-bottom: 0.125rem !important;
}
.my-05 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}
.my-1 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}
.my-2 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}
.my-3 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}
.my-4 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}
.my-5 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}
.my-6 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}
.my-7 {
  margin-top: 3.5rem !important;
  margin-bottom: 3.5rem !important;
}
.my-8 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}
.my-9 {
  margin-top: 4.5rem !important;
  margin-bottom: 4.5rem !important;
}
.my-10 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}
.p-0 {
  padding: 0rem !important;
}
.p-025 {
  padding: 0.125rem !important;
}
.p-05 {
  padding: 0.25rem !important;
}
.p-1 {
  padding: 0.5rem !important;
}
.p-2 {
  padding: 1rem !important;
}
.p-3 {
  padding: 1.5rem !important;
}
.p-4 {
  padding: 2rem !important;
}
.p-5 {
  padding: 2.5rem !important;
}
.p-6 {
  padding: 3rem !important;
}
.p-7 {
  padding: 3.5rem !important;
}
.p-8 {
  padding: 4rem !important;
}
.p-9 {
  padding: 4.5rem !important;
}
.p-10 {
  padding: 5rem !important;
}
.pt-0 {
  padding-top: 0rem !important;
}
.pt-025 {
  padding-top: 0.125rem !important;
}
.pt-05 {
  padding-top: 0.25rem !important;
}
.pt-1 {
  padding-top: 0.5rem !important;
}
.pt-2 {
  padding-top: 1rem !important;
}
.pt-3 {
  padding-top: 1.5rem !important;
}
.pt-4 {
  padding-top: 2rem !important;
}
.pt-5 {
  padding-top: 2.5rem !important;
}
.pt-6 {
  padding-top: 3rem !important;
}
.pt-7 {
  padding-top: 3.5rem !important;
}
.pt-8 {
  padding-top: 4rem !important;
}
.pt-9 {
  padding-top: 4.5rem !important;
}
.pt-10 {
  padding-top: 5rem !important;
}
.pb-0 {
  padding-bottom: 0rem !important;
}
.pb-025 {
  padding-bottom: 0.125rem !important;
}
.pb-05 {
  padding-bottom: 0.25rem !important;
}
.pb-1 {
  padding-bottom: 0.5rem !important;
}
.pb-2 {
  padding-bottom: 1rem !important;
}
.pb-3 {
  padding-bottom: 1.5rem !important;
}
.pb-4 {
  padding-bottom: 2rem !important;
}
.pb-5 {
  padding-bottom: 2.5rem !important;
}
.pb-6 {
  padding-bottom: 3rem !important;
}
.pb-7 {
  padding-bottom: 3.5rem !important;
}
.pb-8 {
  padding-bottom: 4rem !important;
}
.pb-9 {
  padding-bottom: 4.5rem !important;
}
.pb-10 {
  padding-bottom: 5rem !important;
}
.pl-0 {
  padding-left: 0rem !important;
}
.pl-025 {
  padding-left: 0.125rem !important;
}
.pl-05 {
  padding-left: 0.25rem !important;
}
.pl-1 {
  padding-left: 0.5rem !important;
}
.pl-2 {
  padding-left: 1rem !important;
}
.pl-3 {
  padding-left: 1.5rem !important;
}
.pl-4 {
  padding-left: 2rem !important;
}
.pl-5 {
  padding-left: 2.5rem !important;
}
.pl-6 {
  padding-left: 3rem !important;
}
.pl-7 {
  padding-left: 3.5rem !important;
}
.pl-8 {
  padding-left: 4rem !important;
}
.pl-9 {
  padding-left: 4.5rem !important;
}
.pl-10 {
  padding-left: 5rem !important;
}
.pr-0 {
  padding-right: 0rem !important;
}
.pr-025 {
  padding-right: 0.125rem !important;
}
.pr-05 {
  padding-right: 0.25rem !important;
}
.pr-1 {
  padding-right: 0.5rem !important;
}
.pr-2 {
  padding-right: 1rem !important;
}
.pr-3 {
  padding-right: 1.5rem !important;
}
.pr-4 {
  padding-right: 2rem !important;
}
.pr-5 {
  padding-right: 2.5rem !important;
}
.pr-6 {
  padding-right: 3rem !important;
}
.pr-7 {
  padding-right: 3.5rem !important;
}
.pr-8 {
  padding-right: 4rem !important;
}
.pr-9 {
  padding-right: 4.5rem !important;
}
.pr-10 {
  padding-right: 5rem !important;
}
.px-0 {
  padding-left: 0rem !important;
  padding-right: 0rem !important;
}
.px-025 {
  padding-left: 0.125rem !important;
  padding-right: 0.125rem !important;
}
.px-05 {
  padding-left: 0.25rem !important;
  padding-right: 0.25rem !important;
}
.px-1 {
  padding-left: 0.5rem !important;
  padding-right: 0.5rem !important;
}
.px-2 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}
.px-3 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}
.px-4 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}
.px-5 {
  padding-left: 2.5rem !important;
  padding-right: 2.5rem !important;
}
.px-6 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}
.px-7 {
  padding-left: 3.5rem !important;
  padding-right: 3.5rem !important;
}
.px-8 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}
.px-9 {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}
.px-10 {
  padding-left: 5rem !important;
  padding-right: 5rem !important;
}
.py-0 {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}
.py-025 {
  padding-top: 0.125rem !important;
  padding-bottom: 0.125rem !important;
}
.py-05 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}
.py-1 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}
.py-2 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}
.py-3 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}
.py-4 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py-5 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}
.py-6 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}
.py-7 {
  padding-top: 3.5rem !important;
  padding-bottom: 3.5rem !important;
}
.py-8 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}
.py-9 {
  padding-top: 4.5rem !important;
  padding-bottom: 4.5rem !important;
}
.py-10 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0rem !important;
  }
  .m-sm-025 {
    margin: 0.125rem !important;
  }
  .m-sm-05 {
    margin: 0.25rem !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 2rem !important;
  }
  .m-sm-5 {
    margin: 2.5rem !important;
  }
  .m-sm-6 {
    margin: 3rem !important;
  }
  .m-sm-7 {
    margin: 3.5rem !important;
  }
  .m-sm-8 {
    margin: 4rem !important;
  }
  .m-sm-9 {
    margin: 4.5rem !important;
  }
  .m-sm-10 {
    margin: 5rem !important;
  }
  .mt-sm-0 {
    margin-top: 0rem !important;
  }
  .mt-sm-025 {
    margin-top: 0.125rem !important;
  }
  .mt-sm-05 {
    margin-top: 0.25rem !important;
  }
  .mt-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mt-sm-2 {
    margin-top: 1rem !important;
  }
  .mt-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mt-sm-4 {
    margin-top: 2rem !important;
  }
  .mt-sm-5 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-6 {
    margin-top: 3rem !important;
  }
  .mt-sm-7 {
    margin-top: 3.5rem !important;
  }
  .mt-sm-8 {
    margin-top: 4rem !important;
  }
  .mt-sm-9 {
    margin-top: 4.5rem !important;
  }
  .mt-sm-10 {
    margin-top: 5rem !important;
  }
  .mb-sm-0 {
    margin-bottom: 0rem !important;
  }
  .mb-sm-025 {
    margin-bottom: 0.125rem !important;
  }
  .mb-sm-05 {
    margin-bottom: 0.25rem !important;
  }
  .mb-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-sm-2 {
    margin-bottom: 1rem !important;
  }
  .mb-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-sm-4 {
    margin-bottom: 2rem !important;
  }
  .mb-sm-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-6 {
    margin-bottom: 3rem !important;
  }
  .mb-sm-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-sm-8 {
    margin-bottom: 4rem !important;
  }
  .mb-sm-9 {
    margin-bottom: 4.5rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 5rem !important;
  }
  .ml-sm-0 {
    margin-left: 0rem !important;
  }
  .ml-sm-025 {
    margin-left: 0.125rem !important;
  }
  .ml-sm-05 {
    margin-left: 0.25rem !important;
  }
  .ml-sm-1 {
    margin-left: 0.5rem !important;
  }
  .ml-sm-2 {
    margin-left: 1rem !important;
  }
  .ml-sm-3 {
    margin-left: 1.5rem !important;
  }
  .ml-sm-4 {
    margin-left: 2rem !important;
  }
  .ml-sm-5 {
    margin-left: 2.5rem !important;
  }
  .ml-sm-6 {
    margin-left: 3rem !important;
  }
  .ml-sm-7 {
    margin-left: 3.5rem !important;
  }
  .ml-sm-8 {
    margin-left: 4rem !important;
  }
  .ml-sm-9 {
    margin-left: 4.5rem !important;
  }
  .ml-sm-10 {
    margin-left: 5rem !important;
  }
  .mr-sm-0 {
    margin-right: 0rem !important;
  }
  .mr-sm-025 {
    margin-right: 0.125rem !important;
  }
  .mr-sm-05 {
    margin-right: 0.25rem !important;
  }
  .mr-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mr-sm-2 {
    margin-right: 1rem !important;
  }
  .mr-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mr-sm-4 {
    margin-right: 2rem !important;
  }
  .mr-sm-5 {
    margin-right: 2.5rem !important;
  }
  .mr-sm-6 {
    margin-right: 3rem !important;
  }
  .mr-sm-7 {
    margin-right: 3.5rem !important;
  }
  .mr-sm-8 {
    margin-right: 4rem !important;
  }
  .mr-sm-9 {
    margin-right: 4.5rem !important;
  }
  .mr-sm-10 {
    margin-right: 5rem !important;
  }
  .mx-sm-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .mx-sm-025 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .mx-sm-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-sm-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-sm-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-sm-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-sm-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-sm-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-sm-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-sm-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-sm-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-sm-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-sm-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-sm-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .my-sm-025 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .my-sm-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-sm-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-sm-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-sm-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-sm-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-sm-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-sm-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-sm-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-sm-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-sm-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .p-sm-0 {
    padding: 0rem !important;
  }
  .p-sm-025 {
    padding: 0.125rem !important;
  }
  .p-sm-05 {
    padding: 0.25rem !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 2rem !important;
  }
  .p-sm-5 {
    padding: 2.5rem !important;
  }
  .p-sm-6 {
    padding: 3rem !important;
  }
  .p-sm-7 {
    padding: 3.5rem !important;
  }
  .p-sm-8 {
    padding: 4rem !important;
  }
  .p-sm-9 {
    padding: 4.5rem !important;
  }
  .p-sm-10 {
    padding: 5rem !important;
  }
  .pt-sm-0 {
    padding-top: 0rem !important;
  }
  .pt-sm-025 {
    padding-top: 0.125rem !important;
  }
  .pt-sm-05 {
    padding-top: 0.25rem !important;
  }
  .pt-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pt-sm-2 {
    padding-top: 1rem !important;
  }
  .pt-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pt-sm-4 {
    padding-top: 2rem !important;
  }
  .pt-sm-5 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-6 {
    padding-top: 3rem !important;
  }
  .pt-sm-7 {
    padding-top: 3.5rem !important;
  }
  .pt-sm-8 {
    padding-top: 4rem !important;
  }
  .pt-sm-9 {
    padding-top: 4.5rem !important;
  }
  .pt-sm-10 {
    padding-top: 5rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0rem !important;
  }
  .pb-sm-025 {
    padding-bottom: 0.125rem !important;
  }
  .pb-sm-05 {
    padding-bottom: 0.25rem !important;
  }
  .pb-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pb-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-sm-4 {
    padding-bottom: 2rem !important;
  }
  .pb-sm-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-6 {
    padding-bottom: 3rem !important;
  }
  .pb-sm-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-sm-8 {
    padding-bottom: 4rem !important;
  }
  .pb-sm-9 {
    padding-bottom: 4.5rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 5rem !important;
  }
  .pl-sm-0 {
    padding-left: 0rem !important;
  }
  .pl-sm-025 {
    padding-left: 0.125rem !important;
  }
  .pl-sm-05 {
    padding-left: 0.25rem !important;
  }
  .pl-sm-1 {
    padding-left: 0.5rem !important;
  }
  .pl-sm-2 {
    padding-left: 1rem !important;
  }
  .pl-sm-3 {
    padding-left: 1.5rem !important;
  }
  .pl-sm-4 {
    padding-left: 2rem !important;
  }
  .pl-sm-5 {
    padding-left: 2.5rem !important;
  }
  .pl-sm-6 {
    padding-left: 3rem !important;
  }
  .pl-sm-7 {
    padding-left: 3.5rem !important;
  }
  .pl-sm-8 {
    padding-left: 4rem !important;
  }
  .pl-sm-9 {
    padding-left: 4.5rem !important;
  }
  .pl-sm-10 {
    padding-left: 5rem !important;
  }
  .pr-sm-0 {
    padding-right: 0rem !important;
  }
  .pr-sm-025 {
    padding-right: 0.125rem !important;
  }
  .pr-sm-05 {
    padding-right: 0.25rem !important;
  }
  .pr-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pr-sm-2 {
    padding-right: 1rem !important;
  }
  .pr-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pr-sm-4 {
    padding-right: 2rem !important;
  }
  .pr-sm-5 {
    padding-right: 2.5rem !important;
  }
  .pr-sm-6 {
    padding-right: 3rem !important;
  }
  .pr-sm-7 {
    padding-right: 3.5rem !important;
  }
  .pr-sm-8 {
    padding-right: 4rem !important;
  }
  .pr-sm-9 {
    padding-right: 4.5rem !important;
  }
  .pr-sm-10 {
    padding-right: 5rem !important;
  }
  .px-sm-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .px-sm-025 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .px-sm-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-sm-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-sm-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-sm-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-sm-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-sm-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-sm-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-sm-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-sm-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-sm-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-sm-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-sm-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .py-sm-025 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .py-sm-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-sm-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-sm-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-sm-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-sm-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-sm-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-sm-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-sm-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-sm-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-sm-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media (min-width: 768px) {
  .m-md-0 {
    margin: 0rem !important;
  }
  .m-md-025 {
    margin: 0.125rem !important;
  }
  .m-md-05 {
    margin: 0.25rem !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .m-md-4 {
    margin: 2rem !important;
  }
  .m-md-5 {
    margin: 2.5rem !important;
  }
  .m-md-6 {
    margin: 3rem !important;
  }
  .m-md-7 {
    margin: 3.5rem !important;
  }
  .m-md-8 {
    margin: 4rem !important;
  }
  .m-md-9 {
    margin: 4.5rem !important;
  }
  .m-md-10 {
    margin: 5rem !important;
  }
  .mt-md-0 {
    margin-top: 0rem !important;
  }
  .mt-md-025 {
    margin-top: 0.125rem !important;
  }
  .mt-md-05 {
    margin-top: 0.25rem !important;
  }
  .mt-md-1 {
    margin-top: 0.5rem !important;
  }
  .mt-md-2 {
    margin-top: 1rem !important;
  }
  .mt-md-3 {
    margin-top: 1.5rem !important;
  }
  .mt-md-4 {
    margin-top: 2rem !important;
  }
  .mt-md-5 {
    margin-top: 2.5rem !important;
  }
  .mt-md-6 {
    margin-top: 3rem !important;
  }
  .mt-md-7 {
    margin-top: 3.5rem !important;
  }
  .mt-md-8 {
    margin-top: 4rem !important;
  }
  .mt-md-9 {
    margin-top: 4.5rem !important;
  }
  .mt-md-10 {
    margin-top: 5rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0rem !important;
  }
  .mb-md-025 {
    margin-bottom: 0.125rem !important;
  }
  .mb-md-05 {
    margin-bottom: 0.25rem !important;
  }
  .mb-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-md-2 {
    margin-bottom: 1rem !important;
  }
  .mb-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-md-4 {
    margin-bottom: 2rem !important;
  }
  .mb-md-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 3rem !important;
  }
  .mb-md-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-md-8 {
    margin-bottom: 4rem !important;
  }
  .mb-md-9 {
    margin-bottom: 4.5rem !important;
  }
  .mb-md-10 {
    margin-bottom: 5rem !important;
  }
  .ml-md-0 {
    margin-left: 0rem !important;
  }
  .ml-md-025 {
    margin-left: 0.125rem !important;
  }
  .ml-md-05 {
    margin-left: 0.25rem !important;
  }
  .ml-md-1 {
    margin-left: 0.5rem !important;
  }
  .ml-md-2 {
    margin-left: 1rem !important;
  }
  .ml-md-3 {
    margin-left: 1.5rem !important;
  }
  .ml-md-4 {
    margin-left: 2rem !important;
  }
  .ml-md-5 {
    margin-left: 2.5rem !important;
  }
  .ml-md-6 {
    margin-left: 3rem !important;
  }
  .ml-md-7 {
    margin-left: 3.5rem !important;
  }
  .ml-md-8 {
    margin-left: 4rem !important;
  }
  .ml-md-9 {
    margin-left: 4.5rem !important;
  }
  .ml-md-10 {
    margin-left: 5rem !important;
  }
  .mr-md-0 {
    margin-right: 0rem !important;
  }
  .mr-md-025 {
    margin-right: 0.125rem !important;
  }
  .mr-md-05 {
    margin-right: 0.25rem !important;
  }
  .mr-md-1 {
    margin-right: 0.5rem !important;
  }
  .mr-md-2 {
    margin-right: 1rem !important;
  }
  .mr-md-3 {
    margin-right: 1.5rem !important;
  }
  .mr-md-4 {
    margin-right: 2rem !important;
  }
  .mr-md-5 {
    margin-right: 2.5rem !important;
  }
  .mr-md-6 {
    margin-right: 3rem !important;
  }
  .mr-md-7 {
    margin-right: 3.5rem !important;
  }
  .mr-md-8 {
    margin-right: 4rem !important;
  }
  .mr-md-9 {
    margin-right: 4.5rem !important;
  }
  .mr-md-10 {
    margin-right: 5rem !important;
  }
  .mx-md-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .mx-md-025 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .mx-md-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-md-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-md-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-md-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-md-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-md-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-md-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-md-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-md-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-md-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-md-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-md-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .my-md-025 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .my-md-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-md-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-md-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-md-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-md-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-md-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-md-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-md-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-md-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-md-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .p-md-0 {
    padding: 0rem !important;
  }
  .p-md-025 {
    padding: 0.125rem !important;
  }
  .p-md-05 {
    padding: 0.25rem !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .p-md-4 {
    padding: 2rem !important;
  }
  .p-md-5 {
    padding: 2.5rem !important;
  }
  .p-md-6 {
    padding: 3rem !important;
  }
  .p-md-7 {
    padding: 3.5rem !important;
  }
  .p-md-8 {
    padding: 4rem !important;
  }
  .p-md-9 {
    padding: 4.5rem !important;
  }
  .p-md-10 {
    padding: 5rem !important;
  }
  .pt-md-0 {
    padding-top: 0rem !important;
  }
  .pt-md-025 {
    padding-top: 0.125rem !important;
  }
  .pt-md-05 {
    padding-top: 0.25rem !important;
  }
  .pt-md-1 {
    padding-top: 0.5rem !important;
  }
  .pt-md-2 {
    padding-top: 1rem !important;
  }
  .pt-md-3 {
    padding-top: 1.5rem !important;
  }
  .pt-md-4 {
    padding-top: 2rem !important;
  }
  .pt-md-5 {
    padding-top: 2.5rem !important;
  }
  .pt-md-6 {
    padding-top: 3rem !important;
  }
  .pt-md-7 {
    padding-top: 3.5rem !important;
  }
  .pt-md-8 {
    padding-top: 4rem !important;
  }
  .pt-md-9 {
    padding-top: 4.5rem !important;
  }
  .pt-md-10 {
    padding-top: 5rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0rem !important;
  }
  .pb-md-025 {
    padding-bottom: 0.125rem !important;
  }
  .pb-md-05 {
    padding-bottom: 0.25rem !important;
  }
  .pb-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-md-2 {
    padding-bottom: 1rem !important;
  }
  .pb-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-md-4 {
    padding-bottom: 2rem !important;
  }
  .pb-md-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-6 {
    padding-bottom: 3rem !important;
  }
  .pb-md-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-md-8 {
    padding-bottom: 4rem !important;
  }
  .pb-md-9 {
    padding-bottom: 4.5rem !important;
  }
  .pb-md-10 {
    padding-bottom: 5rem !important;
  }
  .pl-md-0 {
    padding-left: 0rem !important;
  }
  .pl-md-025 {
    padding-left: 0.125rem !important;
  }
  .pl-md-05 {
    padding-left: 0.25rem !important;
  }
  .pl-md-1 {
    padding-left: 0.5rem !important;
  }
  .pl-md-2 {
    padding-left: 1rem !important;
  }
  .pl-md-3 {
    padding-left: 1.5rem !important;
  }
  .pl-md-4 {
    padding-left: 2rem !important;
  }
  .pl-md-5 {
    padding-left: 2.5rem !important;
  }
  .pl-md-6 {
    padding-left: 3rem !important;
  }
  .pl-md-7 {
    padding-left: 3.5rem !important;
  }
  .pl-md-8 {
    padding-left: 4rem !important;
  }
  .pl-md-9 {
    padding-left: 4.5rem !important;
  }
  .pl-md-10 {
    padding-left: 5rem !important;
  }
  .pr-md-0 {
    padding-right: 0rem !important;
  }
  .pr-md-025 {
    padding-right: 0.125rem !important;
  }
  .pr-md-05 {
    padding-right: 0.25rem !important;
  }
  .pr-md-1 {
    padding-right: 0.5rem !important;
  }
  .pr-md-2 {
    padding-right: 1rem !important;
  }
  .pr-md-3 {
    padding-right: 1.5rem !important;
  }
  .pr-md-4 {
    padding-right: 2rem !important;
  }
  .pr-md-5 {
    padding-right: 2.5rem !important;
  }
  .pr-md-6 {
    padding-right: 3rem !important;
  }
  .pr-md-7 {
    padding-right: 3.5rem !important;
  }
  .pr-md-8 {
    padding-right: 4rem !important;
  }
  .pr-md-9 {
    padding-right: 4.5rem !important;
  }
  .pr-md-10 {
    padding-right: 5rem !important;
  }
  .px-md-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .px-md-025 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .px-md-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-md-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-md-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-md-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-md-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-md-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-md-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-md-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-md-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-md-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-md-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-md-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .py-md-025 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .py-md-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-md-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-md-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-md-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-md-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-md-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-md-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-md-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-md-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-md-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0rem !important;
  }
  .m-lg-025 {
    margin: 0.125rem !important;
  }
  .m-lg-05 {
    margin: 0.25rem !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 2rem !important;
  }
  .m-lg-5 {
    margin: 2.5rem !important;
  }
  .m-lg-6 {
    margin: 3rem !important;
  }
  .m-lg-7 {
    margin: 3.5rem !important;
  }
  .m-lg-8 {
    margin: 4rem !important;
  }
  .m-lg-9 {
    margin: 4.5rem !important;
  }
  .m-lg-10 {
    margin: 5rem !important;
  }
  .mt-lg-0 {
    margin-top: 0rem !important;
  }
  .mt-lg-025 {
    margin-top: 0.125rem !important;
  }
  .mt-lg-05 {
    margin-top: 0.25rem !important;
  }
  .mt-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mt-lg-2 {
    margin-top: 1rem !important;
  }
  .mt-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mt-lg-4 {
    margin-top: 2rem !important;
  }
  .mt-lg-5 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-6 {
    margin-top: 3rem !important;
  }
  .mt-lg-7 {
    margin-top: 3.5rem !important;
  }
  .mt-lg-8 {
    margin-top: 4rem !important;
  }
  .mt-lg-9 {
    margin-top: 4.5rem !important;
  }
  .mt-lg-10 {
    margin-top: 5rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }
  .mb-lg-025 {
    margin-bottom: 0.125rem !important;
  }
  .mb-lg-05 {
    margin-bottom: 0.25rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 4.5rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 5rem !important;
  }
  .ml-lg-0 {
    margin-left: 0rem !important;
  }
  .ml-lg-025 {
    margin-left: 0.125rem !important;
  }
  .ml-lg-05 {
    margin-left: 0.25rem !important;
  }
  .ml-lg-1 {
    margin-left: 0.5rem !important;
  }
  .ml-lg-2 {
    margin-left: 1rem !important;
  }
  .ml-lg-3 {
    margin-left: 1.5rem !important;
  }
  .ml-lg-4 {
    margin-left: 2rem !important;
  }
  .ml-lg-5 {
    margin-left: 2.5rem !important;
  }
  .ml-lg-6 {
    margin-left: 3rem !important;
  }
  .ml-lg-7 {
    margin-left: 3.5rem !important;
  }
  .ml-lg-8 {
    margin-left: 4rem !important;
  }
  .ml-lg-9 {
    margin-left: 4.5rem !important;
  }
  .ml-lg-10 {
    margin-left: 5rem !important;
  }
  .mr-lg-0 {
    margin-right: 0rem !important;
  }
  .mr-lg-025 {
    margin-right: 0.125rem !important;
  }
  .mr-lg-05 {
    margin-right: 0.25rem !important;
  }
  .mr-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mr-lg-2 {
    margin-right: 1rem !important;
  }
  .mr-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mr-lg-4 {
    margin-right: 2rem !important;
  }
  .mr-lg-5 {
    margin-right: 2.5rem !important;
  }
  .mr-lg-6 {
    margin-right: 3rem !important;
  }
  .mr-lg-7 {
    margin-right: 3.5rem !important;
  }
  .mr-lg-8 {
    margin-right: 4rem !important;
  }
  .mr-lg-9 {
    margin-right: 4.5rem !important;
  }
  .mr-lg-10 {
    margin-right: 5rem !important;
  }
  .mx-lg-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .mx-lg-025 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .mx-lg-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-lg-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-lg-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-lg-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-lg-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-lg-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-lg-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-lg-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-lg-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-lg-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-lg-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-lg-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .my-lg-025 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .my-lg-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-lg-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-lg-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-lg-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-lg-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-lg-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-lg-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-lg-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-lg-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-lg-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .p-lg-0 {
    padding: 0rem !important;
  }
  .p-lg-025 {
    padding: 0.125rem !important;
  }
  .p-lg-05 {
    padding: 0.25rem !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 2rem !important;
  }
  .p-lg-5 {
    padding: 2.5rem !important;
  }
  .p-lg-6 {
    padding: 3rem !important;
  }
  .p-lg-7 {
    padding: 3.5rem !important;
  }
  .p-lg-8 {
    padding: 4rem !important;
  }
  .p-lg-9 {
    padding: 4.5rem !important;
  }
  .p-lg-10 {
    padding: 5rem !important;
  }
  .pt-lg-0 {
    padding-top: 0rem !important;
  }
  .pt-lg-025 {
    padding-top: 0.125rem !important;
  }
  .pt-lg-05 {
    padding-top: 0.25rem !important;
  }
  .pt-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pt-lg-2 {
    padding-top: 1rem !important;
  }
  .pt-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pt-lg-4 {
    padding-top: 2rem !important;
  }
  .pt-lg-5 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-6 {
    padding-top: 3rem !important;
  }
  .pt-lg-7 {
    padding-top: 3.5rem !important;
  }
  .pt-lg-8 {
    padding-top: 4rem !important;
  }
  .pt-lg-9 {
    padding-top: 4.5rem !important;
  }
  .pt-lg-10 {
    padding-top: 5rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0rem !important;
  }
  .pb-lg-025 {
    padding-bottom: 0.125rem !important;
  }
  .pb-lg-05 {
    padding-bottom: 0.25rem !important;
  }
  .pb-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pb-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-lg-4 {
    padding-bottom: 2rem !important;
  }
  .pb-lg-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-6 {
    padding-bottom: 3rem !important;
  }
  .pb-lg-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-lg-8 {
    padding-bottom: 4rem !important;
  }
  .pb-lg-9 {
    padding-bottom: 4.5rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 5rem !important;
  }
  .pl-lg-0 {
    padding-left: 0rem !important;
  }
  .pl-lg-025 {
    padding-left: 0.125rem !important;
  }
  .pl-lg-05 {
    padding-left: 0.25rem !important;
  }
  .pl-lg-1 {
    padding-left: 0.5rem !important;
  }
  .pl-lg-2 {
    padding-left: 1rem !important;
  }
  .pl-lg-3 {
    padding-left: 1.5rem !important;
  }
  .pl-lg-4 {
    padding-left: 2rem !important;
  }
  .pl-lg-5 {
    padding-left: 2.5rem !important;
  }
  .pl-lg-6 {
    padding-left: 3rem !important;
  }
  .pl-lg-7 {
    padding-left: 3.5rem !important;
  }
  .pl-lg-8 {
    padding-left: 4rem !important;
  }
  .pl-lg-9 {
    padding-left: 4.5rem !important;
  }
  .pl-lg-10 {
    padding-left: 5rem !important;
  }
  .pr-lg-0 {
    padding-right: 0rem !important;
  }
  .pr-lg-025 {
    padding-right: 0.125rem !important;
  }
  .pr-lg-05 {
    padding-right: 0.25rem !important;
  }
  .pr-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pr-lg-2 {
    padding-right: 1rem !important;
  }
  .pr-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pr-lg-4 {
    padding-right: 2rem !important;
  }
  .pr-lg-5 {
    padding-right: 2.5rem !important;
  }
  .pr-lg-6 {
    padding-right: 3rem !important;
  }
  .pr-lg-7 {
    padding-right: 3.5rem !important;
  }
  .pr-lg-8 {
    padding-right: 4rem !important;
  }
  .pr-lg-9 {
    padding-right: 4.5rem !important;
  }
  .pr-lg-10 {
    padding-right: 5rem !important;
  }
  .px-lg-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .px-lg-025 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .px-lg-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-lg-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-lg-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-lg-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-lg-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-lg-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-lg-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-lg-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-lg-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-lg-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-lg-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-lg-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .py-lg-025 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .py-lg-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-lg-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-lg-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-lg-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-lg-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-lg-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-lg-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-lg-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-lg-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-lg-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media (min-width: 1200px) {
  .m-xl-0 {
    margin: 0rem !important;
  }
  .m-xl-025 {
    margin: 0.125rem !important;
  }
  .m-xl-05 {
    margin: 0.25rem !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 2rem !important;
  }
  .m-xl-5 {
    margin: 2.5rem !important;
  }
  .m-xl-6 {
    margin: 3rem !important;
  }
  .m-xl-7 {
    margin: 3.5rem !important;
  }
  .m-xl-8 {
    margin: 4rem !important;
  }
  .m-xl-9 {
    margin: 4.5rem !important;
  }
  .m-xl-10 {
    margin: 5rem !important;
  }
  .mt-xl-0 {
    margin-top: 0rem !important;
  }
  .mt-xl-025 {
    margin-top: 0.125rem !important;
  }
  .mt-xl-05 {
    margin-top: 0.25rem !important;
  }
  .mt-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xl-2 {
    margin-top: 1rem !important;
  }
  .mt-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xl-4 {
    margin-top: 2rem !important;
  }
  .mt-xl-5 {
    margin-top: 2.5rem !important;
  }
  .mt-xl-6 {
    margin-top: 3rem !important;
  }
  .mt-xl-7 {
    margin-top: 3.5rem !important;
  }
  .mt-xl-8 {
    margin-top: 4rem !important;
  }
  .mt-xl-9 {
    margin-top: 4.5rem !important;
  }
  .mt-xl-10 {
    margin-top: 5rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xl-025 {
    margin-bottom: 0.125rem !important;
  }
  .mb-xl-05 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 5rem !important;
  }
  .ml-xl-0 {
    margin-left: 0rem !important;
  }
  .ml-xl-025 {
    margin-left: 0.125rem !important;
  }
  .ml-xl-05 {
    margin-left: 0.25rem !important;
  }
  .ml-xl-1 {
    margin-left: 0.5rem !important;
  }
  .ml-xl-2 {
    margin-left: 1rem !important;
  }
  .ml-xl-3 {
    margin-left: 1.5rem !important;
  }
  .ml-xl-4 {
    margin-left: 2rem !important;
  }
  .ml-xl-5 {
    margin-left: 2.5rem !important;
  }
  .ml-xl-6 {
    margin-left: 3rem !important;
  }
  .ml-xl-7 {
    margin-left: 3.5rem !important;
  }
  .ml-xl-8 {
    margin-left: 4rem !important;
  }
  .ml-xl-9 {
    margin-left: 4.5rem !important;
  }
  .ml-xl-10 {
    margin-left: 5rem !important;
  }
  .mr-xl-0 {
    margin-right: 0rem !important;
  }
  .mr-xl-025 {
    margin-right: 0.125rem !important;
  }
  .mr-xl-05 {
    margin-right: 0.25rem !important;
  }
  .mr-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mr-xl-2 {
    margin-right: 1rem !important;
  }
  .mr-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mr-xl-4 {
    margin-right: 2rem !important;
  }
  .mr-xl-5 {
    margin-right: 2.5rem !important;
  }
  .mr-xl-6 {
    margin-right: 3rem !important;
  }
  .mr-xl-7 {
    margin-right: 3.5rem !important;
  }
  .mr-xl-8 {
    margin-right: 4rem !important;
  }
  .mr-xl-9 {
    margin-right: 4.5rem !important;
  }
  .mr-xl-10 {
    margin-right: 5rem !important;
  }
  .mx-xl-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .mx-xl-025 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .mx-xl-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xl-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xl-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xl-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xl-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xl-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xl-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xl-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xl-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xl-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-xl-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .my-xl-025 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .my-xl-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xl-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xl-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .p-xl-0 {
    padding: 0rem !important;
  }
  .p-xl-025 {
    padding: 0.125rem !important;
  }
  .p-xl-05 {
    padding: 0.25rem !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 2rem !important;
  }
  .p-xl-5 {
    padding: 2.5rem !important;
  }
  .p-xl-6 {
    padding: 3rem !important;
  }
  .p-xl-7 {
    padding: 3.5rem !important;
  }
  .p-xl-8 {
    padding: 4rem !important;
  }
  .p-xl-9 {
    padding: 4.5rem !important;
  }
  .p-xl-10 {
    padding: 5rem !important;
  }
  .pt-xl-0 {
    padding-top: 0rem !important;
  }
  .pt-xl-025 {
    padding-top: 0.125rem !important;
  }
  .pt-xl-05 {
    padding-top: 0.25rem !important;
  }
  .pt-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xl-2 {
    padding-top: 1rem !important;
  }
  .pt-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xl-4 {
    padding-top: 2rem !important;
  }
  .pt-xl-5 {
    padding-top: 2.5rem !important;
  }
  .pt-xl-6 {
    padding-top: 3rem !important;
  }
  .pt-xl-7 {
    padding-top: 3.5rem !important;
  }
  .pt-xl-8 {
    padding-top: 4rem !important;
  }
  .pt-xl-9 {
    padding-top: 4.5rem !important;
  }
  .pt-xl-10 {
    padding-top: 5rem !important;
  }
  .pb-xl-0 {
    padding-bottom: 0rem !important;
  }
  .pb-xl-025 {
    padding-bottom: 0.125rem !important;
  }
  .pb-xl-05 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xl-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xl-6 {
    padding-bottom: 3rem !important;
  }
  .pb-xl-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xl-8 {
    padding-bottom: 4rem !important;
  }
  .pb-xl-9 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xl-10 {
    padding-bottom: 5rem !important;
  }
  .pl-xl-0 {
    padding-left: 0rem !important;
  }
  .pl-xl-025 {
    padding-left: 0.125rem !important;
  }
  .pl-xl-05 {
    padding-left: 0.25rem !important;
  }
  .pl-xl-1 {
    padding-left: 0.5rem !important;
  }
  .pl-xl-2 {
    padding-left: 1rem !important;
  }
  .pl-xl-3 {
    padding-left: 1.5rem !important;
  }
  .pl-xl-4 {
    padding-left: 2rem !important;
  }
  .pl-xl-5 {
    padding-left: 2.5rem !important;
  }
  .pl-xl-6 {
    padding-left: 3rem !important;
  }
  .pl-xl-7 {
    padding-left: 3.5rem !important;
  }
  .pl-xl-8 {
    padding-left: 4rem !important;
  }
  .pl-xl-9 {
    padding-left: 4.5rem !important;
  }
  .pl-xl-10 {
    padding-left: 5rem !important;
  }
  .pr-xl-0 {
    padding-right: 0rem !important;
  }
  .pr-xl-025 {
    padding-right: 0.125rem !important;
  }
  .pr-xl-05 {
    padding-right: 0.25rem !important;
  }
  .pr-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pr-xl-2 {
    padding-right: 1rem !important;
  }
  .pr-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pr-xl-4 {
    padding-right: 2rem !important;
  }
  .pr-xl-5 {
    padding-right: 2.5rem !important;
  }
  .pr-xl-6 {
    padding-right: 3rem !important;
  }
  .pr-xl-7 {
    padding-right: 3.5rem !important;
  }
  .pr-xl-8 {
    padding-right: 4rem !important;
  }
  .pr-xl-9 {
    padding-right: 4.5rem !important;
  }
  .pr-xl-10 {
    padding-right: 5rem !important;
  }
  .px-xl-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .px-xl-025 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .px-xl-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xl-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xl-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xl-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xl-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xl-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xl-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xl-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xl-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xl-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-xl-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .py-xl-025 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .py-xl-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xl-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xl-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

@media (min-width: 1400px) {
  .m-xxl-0 {
    margin: 0rem !important;
  }
  .m-xxl-025 {
    margin: 0.125rem !important;
  }
  .m-xxl-05 {
    margin: 0.25rem !important;
  }
  .m-xxl-1 {
    margin: 0.5rem !important;
  }
  .m-xxl-2 {
    margin: 1rem !important;
  }
  .m-xxl-3 {
    margin: 1.5rem !important;
  }
  .m-xxl-4 {
    margin: 2rem !important;
  }
  .m-xxl-5 {
    margin: 2.5rem !important;
  }
  .m-xxl-6 {
    margin: 3rem !important;
  }
  .m-xxl-7 {
    margin: 3.5rem !important;
  }
  .m-xxl-8 {
    margin: 4rem !important;
  }
  .m-xxl-9 {
    margin: 4.5rem !important;
  }
  .m-xxl-10 {
    margin: 5rem !important;
  }
  .mt-xxl-0 {
    margin-top: 0rem !important;
  }
  .mt-xxl-025 {
    margin-top: 0.125rem !important;
  }
  .mt-xxl-05 {
    margin-top: 0.25rem !important;
  }
  .mt-xxl-1 {
    margin-top: 0.5rem !important;
  }
  .mt-xxl-2 {
    margin-top: 1rem !important;
  }
  .mt-xxl-3 {
    margin-top: 1.5rem !important;
  }
  .mt-xxl-4 {
    margin-top: 2rem !important;
  }
  .mt-xxl-5 {
    margin-top: 2.5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 3rem !important;
  }
  .mt-xxl-7 {
    margin-top: 3.5rem !important;
  }
  .mt-xxl-8 {
    margin-top: 4rem !important;
  }
  .mt-xxl-9 {
    margin-top: 4.5rem !important;
  }
  .mt-xxl-10 {
    margin-top: 5rem !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xxl-025 {
    margin-bottom: 0.125rem !important;
  }
  .mb-xxl-05 {
    margin-bottom: 0.25rem !important;
  }
  .mb-xxl-1 {
    margin-bottom: 0.5rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 1.5rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 2.5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 3.5rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 4.5rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 5rem !important;
  }
  .ml-xxl-0 {
    margin-left: 0rem !important;
  }
  .ml-xxl-025 {
    margin-left: 0.125rem !important;
  }
  .ml-xxl-05 {
    margin-left: 0.25rem !important;
  }
  .ml-xxl-1 {
    margin-left: 0.5rem !important;
  }
  .ml-xxl-2 {
    margin-left: 1rem !important;
  }
  .ml-xxl-3 {
    margin-left: 1.5rem !important;
  }
  .ml-xxl-4 {
    margin-left: 2rem !important;
  }
  .ml-xxl-5 {
    margin-left: 2.5rem !important;
  }
  .ml-xxl-6 {
    margin-left: 3rem !important;
  }
  .ml-xxl-7 {
    margin-left: 3.5rem !important;
  }
  .ml-xxl-8 {
    margin-left: 4rem !important;
  }
  .ml-xxl-9 {
    margin-left: 4.5rem !important;
  }
  .ml-xxl-10 {
    margin-left: 5rem !important;
  }
  .mr-xxl-0 {
    margin-right: 0rem !important;
  }
  .mr-xxl-025 {
    margin-right: 0.125rem !important;
  }
  .mr-xxl-05 {
    margin-right: 0.25rem !important;
  }
  .mr-xxl-1 {
    margin-right: 0.5rem !important;
  }
  .mr-xxl-2 {
    margin-right: 1rem !important;
  }
  .mr-xxl-3 {
    margin-right: 1.5rem !important;
  }
  .mr-xxl-4 {
    margin-right: 2rem !important;
  }
  .mr-xxl-5 {
    margin-right: 2.5rem !important;
  }
  .mr-xxl-6 {
    margin-right: 3rem !important;
  }
  .mr-xxl-7 {
    margin-right: 3.5rem !important;
  }
  .mr-xxl-8 {
    margin-right: 4rem !important;
  }
  .mr-xxl-9 {
    margin-right: 4.5rem !important;
  }
  .mr-xxl-10 {
    margin-right: 5rem !important;
  }
  .mx-xxl-0 {
    margin-left: 0rem !important;
    margin-right: 0rem !important;
  }
  .mx-xxl-025 {
    margin-left: 0.125rem !important;
    margin-right: 0.125rem !important;
  }
  .mx-xxl-05 {
    margin-left: 0.25rem !important;
    margin-right: 0.25rem !important;
  }
  .mx-xxl-1 {
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
  }
  .mx-xxl-2 {
    margin-left: 1rem !important;
    margin-right: 1rem !important;
  }
  .mx-xxl-3 {
    margin-left: 1.5rem !important;
    margin-right: 1.5rem !important;
  }
  .mx-xxl-4 {
    margin-left: 2rem !important;
    margin-right: 2rem !important;
  }
  .mx-xxl-5 {
    margin-left: 2.5rem !important;
    margin-right: 2.5rem !important;
  }
  .mx-xxl-6 {
    margin-left: 3rem !important;
    margin-right: 3rem !important;
  }
  .mx-xxl-7 {
    margin-left: 3.5rem !important;
    margin-right: 3.5rem !important;
  }
  .mx-xxl-8 {
    margin-left: 4rem !important;
    margin-right: 4rem !important;
  }
  .mx-xxl-9 {
    margin-left: 4.5rem !important;
    margin-right: 4.5rem !important;
  }
  .mx-xxl-10 {
    margin-left: 5rem !important;
    margin-right: 5rem !important;
  }
  .my-xxl-0 {
    margin-top: 0rem !important;
    margin-bottom: 0rem !important;
  }
  .my-xxl-025 {
    margin-top: 0.125rem !important;
    margin-bottom: 0.125rem !important;
  }
  .my-xxl-05 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }
  .my-xxl-1 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
  .my-xxl-2 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }
  .my-xxl-3 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .my-xxl-4 {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }
  .my-xxl-5 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-xxl-6 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }
  .my-xxl-7 {
    margin-top: 3.5rem !important;
    margin-bottom: 3.5rem !important;
  }
  .my-xxl-8 {
    margin-top: 4rem !important;
    margin-bottom: 4rem !important;
  }
  .my-xxl-9 {
    margin-top: 4.5rem !important;
    margin-bottom: 4.5rem !important;
  }
  .my-xxl-10 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .p-xxl-0 {
    padding: 0rem !important;
  }
  .p-xxl-025 {
    padding: 0.125rem !important;
  }
  .p-xxl-05 {
    padding: 0.25rem !important;
  }
  .p-xxl-1 {
    padding: 0.5rem !important;
  }
  .p-xxl-2 {
    padding: 1rem !important;
  }
  .p-xxl-3 {
    padding: 1.5rem !important;
  }
  .p-xxl-4 {
    padding: 2rem !important;
  }
  .p-xxl-5 {
    padding: 2.5rem !important;
  }
  .p-xxl-6 {
    padding: 3rem !important;
  }
  .p-xxl-7 {
    padding: 3.5rem !important;
  }
  .p-xxl-8 {
    padding: 4rem !important;
  }
  .p-xxl-9 {
    padding: 4.5rem !important;
  }
  .p-xxl-10 {
    padding: 5rem !important;
  }
  .pt-xxl-0 {
    padding-top: 0rem !important;
  }
  .pt-xxl-025 {
    padding-top: 0.125rem !important;
  }
  .pt-xxl-05 {
    padding-top: 0.25rem !important;
  }
  .pt-xxl-1 {
    padding-top: 0.5rem !important;
  }
  .pt-xxl-2 {
    padding-top: 1rem !important;
  }
  .pt-xxl-3 {
    padding-top: 1.5rem !important;
  }
  .pt-xxl-4 {
    padding-top: 2rem !important;
  }
  .pt-xxl-5 {
    padding-top: 2.5rem !important;
  }
  .pt-xxl-6 {
    padding-top: 3rem !important;
  }
  .pt-xxl-7 {
    padding-top: 3.5rem !important;
  }
  .pt-xxl-8 {
    padding-top: 4rem !important;
  }
  .pt-xxl-9 {
    padding-top: 4.5rem !important;
  }
  .pt-xxl-10 {
    padding-top: 5rem !important;
  }
  .pb-xxl-0 {
    padding-bottom: 0rem !important;
  }
  .pb-xxl-025 {
    padding-bottom: 0.125rem !important;
  }
  .pb-xxl-05 {
    padding-bottom: 0.25rem !important;
  }
  .pb-xxl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pb-xxl-2 {
    padding-bottom: 1rem !important;
  }
  .pb-xxl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pb-xxl-4 {
    padding-bottom: 2rem !important;
  }
  .pb-xxl-5 {
    padding-bottom: 2.5rem !important;
  }
  .pb-xxl-6 {
    padding-bottom: 3rem !important;
  }
  .pb-xxl-7 {
    padding-bottom: 3.5rem !important;
  }
  .pb-xxl-8 {
    padding-bottom: 4rem !important;
  }
  .pb-xxl-9 {
    padding-bottom: 4.5rem !important;
  }
  .pb-xxl-10 {
    padding-bottom: 5rem !important;
  }
  .pl-xxl-0 {
    padding-left: 0rem !important;
  }
  .pl-xxl-025 {
    padding-left: 0.125rem !important;
  }
  .pl-xxl-05 {
    padding-left: 0.25rem !important;
  }
  .pl-xxl-1 {
    padding-left: 0.5rem !important;
  }
  .pl-xxl-2 {
    padding-left: 1rem !important;
  }
  .pl-xxl-3 {
    padding-left: 1.5rem !important;
  }
  .pl-xxl-4 {
    padding-left: 2rem !important;
  }
  .pl-xxl-5 {
    padding-left: 2.5rem !important;
  }
  .pl-xxl-6 {
    padding-left: 3rem !important;
  }
  .pl-xxl-7 {
    padding-left: 3.5rem !important;
  }
  .pl-xxl-8 {
    padding-left: 4rem !important;
  }
  .pl-xxl-9 {
    padding-left: 4.5rem !important;
  }
  .pl-xxl-10 {
    padding-left: 5rem !important;
  }
  .pr-xxl-0 {
    padding-right: 0rem !important;
  }
  .pr-xxl-025 {
    padding-right: 0.125rem !important;
  }
  .pr-xxl-05 {
    padding-right: 0.25rem !important;
  }
  .pr-xxl-1 {
    padding-right: 0.5rem !important;
  }
  .pr-xxl-2 {
    padding-right: 1rem !important;
  }
  .pr-xxl-3 {
    padding-right: 1.5rem !important;
  }
  .pr-xxl-4 {
    padding-right: 2rem !important;
  }
  .pr-xxl-5 {
    padding-right: 2.5rem !important;
  }
  .pr-xxl-6 {
    padding-right: 3rem !important;
  }
  .pr-xxl-7 {
    padding-right: 3.5rem !important;
  }
  .pr-xxl-8 {
    padding-right: 4rem !important;
  }
  .pr-xxl-9 {
    padding-right: 4.5rem !important;
  }
  .pr-xxl-10 {
    padding-right: 5rem !important;
  }
  .px-xxl-0 {
    padding-left: 0rem !important;
    padding-right: 0rem !important;
  }
  .px-xxl-025 {
    padding-left: 0.125rem !important;
    padding-right: 0.125rem !important;
  }
  .px-xxl-05 {
    padding-left: 0.25rem !important;
    padding-right: 0.25rem !important;
  }
  .px-xxl-1 {
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
  }
  .px-xxl-2 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .px-xxl-3 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  .px-xxl-4 {
    padding-left: 2rem !important;
    padding-right: 2rem !important;
  }
  .px-xxl-5 {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
  }
  .px-xxl-6 {
    padding-left: 3rem !important;
    padding-right: 3rem !important;
  }
  .px-xxl-7 {
    padding-left: 3.5rem !important;
    padding-right: 3.5rem !important;
  }
  .px-xxl-8 {
    padding-left: 4rem !important;
    padding-right: 4rem !important;
  }
  .px-xxl-9 {
    padding-left: 4.5rem !important;
    padding-right: 4.5rem !important;
  }
  .px-xxl-10 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
  }
  .py-xxl-0 {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
  }
  .py-xxl-025 {
    padding-top: 0.125rem !important;
    padding-bottom: 0.125rem !important;
  }
  .py-xxl-05 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }
  .py-xxl-1 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }
  .py-xxl-2 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .py-xxl-3 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .py-xxl-4 {
    padding-top: 2rem !important;
    padding-bottom: 2rem !important;
  }
  .py-xxl-5 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-xxl-6 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }
  .py-xxl-7 {
    padding-top: 3.5rem !important;
    padding-bottom: 3.5rem !important;
  }
  .py-xxl-8 {
    padding-top: 4rem !important;
    padding-bottom: 4rem !important;
  }
  .py-xxl-9 {
    padding-top: 4.5rem !important;
    padding-bottom: 4.5rem !important;
  }
  .py-xxl-10 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
}

